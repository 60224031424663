:root {
  --main-theme-color: #771e24;
  --dark-bg-color: #e8e8e8;
  --heading-color: #0a0d14;
  --paragraph-color: #777c87;
}

a,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  margin: 0;
}
